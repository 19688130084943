// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pay-index-js": () => import("./../src/pages/pay/index.js" /* webpackChunkName: "component---src-pages-pay-index-js" */),
  "component---src-pages-writing-index-js": () => import("./../src/pages/writing/index.js" /* webpackChunkName: "component---src-pages-writing-index-js" */),
  "component---src-pages-writing-tags-js": () => import("./../src/pages/writing/tags.js" /* webpackChunkName: "component---src-pages-writing-tags-js" */),
  "component---src-templates-post-post-js": () => import("./../src/templates/Post/Post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/Tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

